<style scoped>
    .contact-view{width: 100%; background-color:white;display: flex;}
    .list-group li.active {
        background: linear-gradient(0deg,#67aac4,#84bdbd 50%,#64839a);
    }
    .card-header {
        background: linear-gradient(0deg,#67aac4,#84bdbd 50%,#64839a);
        color: #fff!important;
        font-weight: 700;
    }
    h3 {
        color: #6dafc4;
        text-align: left;
        font-weight: 700;
    }
    h4 {
        color: #6dafc4;
        font-weight: 700;
    }
    h4, p {
        text-align: left;
    }
    .list-group-item:hover{cursor: pointer;}
    .telNumberN{text-decoration-line: underline;}
    .telNumberN:hover{cursor:pointer;}
</style>
<template>
    <div class="contact-view flex-column h-100">
        <div class="d-flex flex-column flex-sm-row h-100">
            <div class="col-12 col-sm-3 p-3 pe-sm-2 pe-lg-3">
                <ul class="list-group">
                    <li class="list-group-item" :class="{'active':showNumber==1}" @click="showNumber=1">新手引导</li>
                    <li class="list-group-item" :class="{'active':showNumber==2}" @click="showNumber=2">游戏问题</li>
                    <li class="list-group-item" :class="{'active':showNumber==3}" @click="showNumber=3">联系我们</li>
                </ul>
            </div>
            <div class="tab-content flex-grow-1 h-0 h-sm-auto col-12 col-sm-9 px-3 ps-sm-2 pe-sm-3 px-lg-3 py-sm-3 pb-3">
                <div class="h-100 overflow-scroll" id="home" v-show="showNumber==1">
                    <h3>在线客服</h3>
                    <p>客服24小时在线，如有需要，请随时联系我们！</p>
                    <div class="d-flex justify-content-left">
                        <button v-for="item in chatList" :key="item" type="button" class="btn btn-item me-2" @click="toChat(item.url)">
                            <i class="bi bi-chat-dots-fill me-1"></i>
                            <span>{{item.text}}</span>
                        </button>
                    </div>
                    <br>
                    <h3>联系方式</h3>
                    <div v-for="item in telList" :key="item" class="text-start p-1">
                        <span>{{item.text}}：</span>
                        <a @click="goLink(item.url)" class="telNumberN">{{item.telNumberN}}</a>
                    </div>

                    <h3>建议与投诉</h3>
                    <p>现场投诉号：{{complaintNo1}}；网投投诉号：{{complaintNo2}}；如有建议，投诉，欢迎投诉！</p>
                </div>
                <div id="home2" class="card h-100" v-show="showNumber==2">
                    <div class="card-header">常见问题</div>
                    <div class="card-body flex-grow-1 h-0 p-0">
                        <div class="h-100 overflow-scroll p-3">
                            <h4>{{problemTitle1}}</h4>
                            <p>{{problemCon1}}</p>
                            <h4>{{problemTitle2}}</h4>
                            <p>{{problemCon2}}</p>
                            <h4>{{problemTitle3}}</h4>
                            <p>{{problemCon3}}</p>
                        </div>
                    </div>
                </div>
                <div id="home3" class="card h-100" v-show="showNumber==3">
                    <div class="card-header">联系方式</div>
                    <div class="card-body flex-grow-1 h-0 p-0">
                        <div class="h-100 overflow-scroll py-2 px-1">
                            <div class="d-flex flex-wrap">
                                <div v-for="item in contactList" :key="item" class="d-flex flex-column col-4 col-md-3 col-xl-2 px-1">
                                    <img class="mx-auto d-block img-fluid img-thumbnail" :src="'images/'+item.img">
                                    <span class="small">{{item.text}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        data(){
            return {
                telNumberN:config.telNumberN,
                complaintNo1:config.complaintNo1,
                complaintNo2:config.complaintNo2,
                problemTitle1:config.problemTitle1,
                problemCon1:config.problemCon1,
                problemTitle2:config.problemTitle2,
                problemCon2:config.problemCon2,
                problemTitle3:config.problemTitle3,
                problemCon3:config.problemCon3,
                contactList:config.contactList,
                showNumber:1,//要显示的模块的编号
                chatList:config.chatList,//在线客服按钮列表
                telList:config.telList,
            }
        },
        methods:{
            /**
             * 跳转到客服链接
             */
            toChat(url){
                window.open(url,'_blank');
            },
            goLink(url){
                window.location.href=url;
            }
        }
    }
</script>