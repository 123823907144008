<style scoped>
        html,
        body {
            height: 100%;
            background-color: #F3F3F3;
            padding:0;
            margin:0;
        }
        .login { 
            background-color: #030000 !important;
            background:url('@/assets/images/loginbg2.jpg') no-repeat;
            background-size: cover;
        }
        h1 {
            font-size: 24px;
            text-align: center;
            background-image: linear-gradient(180deg,#ffffff,#c54505);
            background-clip: text;
            -webkit-background-clip: text;
            color: transparent;
        }
        .card{
            background-color: #231207;
            border: 2px #d77617  solid;
            padding: 2px;
        }
        .card-header{
            color:#d78c17;
        }
        .login-input {
            border: 1px solid #d77617;
            color: #d78c17;
            padding: 5px;
            border-radius: 10px;
            margin-bottom: 15px;
        }
        img {
            max-width: 100%;
            height: 70%;
            margin-top:1%;
            vertical-align: sub;
        }
        input {
            background-color: transparent;
            border: 0;
            color: #ef8b43;
            box-sizing: border-box;
        }
        #login_button{
            background-color:#d77617;
            color:#fff;
        }
        @media (max-width:575px) {
            /* .loginCon {
                width:60%;
            } */
        }
        @media (min-width:576px) and (max-width:767px) { 
            .loginCon {
                width:40%;
            }
            .loginCon img { 
                width:15%;vertical-align: middle;
            }
            .loginCon input { 
                border:0;
                width:80%;
                height:100%; font-size: 1rem;
            }
        }
    </style>
<template>
    <div class="login w-100 h-100 position-absolute">
        <div class="d-flex flex-column justify-content-center col-8 col-sm-6 col-md-5 col-lg-4 col-xl-3 text-start mx-auto h-100">
            <div class="card">
                <h1 class="mt-2">导航网页登录界面</h1>
                <div class="card-header fw-bold">|&nbsp;&nbsp;账号登录</div>
                <div class="card-body">
                    <div class="d-flex justify-content-between mb-3">
                        <div class="d-flex flex-column justify-content-around w-100 me-3 text-nowrap">
                    
                            <div class="login-input d-flex justify-content-center w-100 col mb-3">
                                
                                <label for="u">用户名:&nbsp; </label>
                                <input type="text" class="w-100" id="u" name="username">
                            </div>
                            <div class=" login-input d-flex justify-content-center w-100 col">
                               
                                <label for="p">密&nbsp;&nbsp;&nbsp;&nbsp;码:&nbsp;</label>
                                <input type="password" class="w-100" id="p" name="pwd">
                            </div>
                        </div>
                        <!-- <div class="flex-grow-1">
                            <input type="text" class="form-control mb-3" id="u" placeholder="用户名" name="username">
                            <input type="password" class="form-control" id="p" placeholder="密码" name="pwd">
                        </div> -->
                    </div>
                    <div class="text-center">
                        <button class="btn ps-4 pe-4 w-100" id="login_button">登录</button>
                    </div>
                </div> 
            </div>
        </div>
    </div>
</template>
<script>
    export default{
        mounted(){
            let _this=this;
            $(function () {
        //点击登录
        $('#u').attr('name', 'u' + new Date().getTime());
        $('#p').attr('name', 'p' + new Date().getTime());
        $('#p').focus(function (e) {
            $(e.target).attr('type', 'password');
        })
        $('#login_button').click(()=> {
            var u = $('#u').val();
            var p = $('#p').val();
            if (u == '' || u == null) {
                alert('请输入账号');
                return;
            } else if (p == '' || p == null) {
                alert('请输入密码');
                return;
            }
            else if (u == '888888' && p == '666666') {
                sessionStorage.setItem('userInfo', u);
                _this.$router.push('/home');
                // window.location.href = '/home';
            }
            else {
                alert('账号或密码输入错误');
            }
        })
        //回车
        $(window).keydown(function (e) {
            e.keyCode == 13 && $('#login_button').click();
        })

    })
        }
    }
</script>