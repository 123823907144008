<style scoped>
    .promotion-view{width: 100%; background-color:#000000;display: flex;}
    .btn-item{
        background: linear-gradient(0deg,#64a7c4 0%, #85c1c0 50%, #628198 100%);
        color: white !important;
        border-color: #64a7c4;
    }
    .btn-downitem{
        background: linear-gradient(0deg,#51405d 0%, #a87ac9 50%, #51405d 100%);
        color: white !important;
        border-color: #64a7c4;
    }
    .logo-text{color:#faee67; background-image:-webkit-linear-gradient(top,#faee67,#fd9933);-webkit-background-clip:text;-webkit-text-fill-color:transparent;}
    .probg{background: url('@/assets/images/promotionbg.png') center 10% / cover no-repeat;}
    .contentbg{background:url('@/assets/images/conbg.png') no-repeat center bottom;}
    .bigtext{
        color: #fbc8de;text-align: left; font-weight: bold;font-size:1.2rem;
        text-shadow: 0px 0px 0.1rem #4b004f, 0px 0px 0.2rem #4b004f, 0px 0px 0.3rem #4b004f, 0px 0px 0.4rem #4b004f, 0px 0px 0.5rem #4b004f, 0px 0px 0.6rem #4b004f, 0px 0px 0.7rem #4b004f, 0px 0px 0.8rem #4b004f, 0px 0px 0.9rem #4b004f, 0px 0px 1rem #4b004f;}
    p{text-align: left;}
    .title-div{border-top:2px #eab55a solid;border-bottom:2px #eab55a solid;}
    .kf{
        background-color:#df00e3;line-height: 2em;border-radius: 50px;box-shadow: 0px 0px 5px 6px #a019ba;font-weight: bold;
        border-top: 2px solid #ffebf7;border-bottom:2px solid #e54efc;border-left: 2px solid #fbb8ec;border-right: 2px solid #fbb8ec;
    }
    .gw{background-color:#dc080d;border-radius: 50px;}
    .star{color:#f0a040;background-image:-webkit-linear-gradient(top,#ffe890,#f06f1c);-webkit-background-clip:text;-webkit-text-fill-color:transparent;}
    .footyy{color:#f8d459;font-size:1.2em}
</style>
<template>
    <div class="d-flex flex-column h-100 w-100">
        <!-- 头部导航栏 -->
        <div class="bg-black index-1">
            <div class="container">
                <div class="d-flex justify-content-center ">
                    <div class="py-2 d-flex flex-column">
                        <img style="max-width:5.8rem" src="images/logo.png"/>
                        <span class="logo-text">全球华人娱乐场所</span>
                    </div>
                </div>
            </div>
        </div>

        <!-- 内容部分 -->
			
            <div class="promotion-view flex-grow-1 flex-column d-flex justify-content-start justify-content-sm-center position-relative">
                <div class="d-flex flex-column flex-sm-row h-100">
                    <div class="col-12 col-sm-6 probg flex-grow-1">
                        <!-- <img class="mx-auto img-fluid" src="@/assets/images/promotionbg.png"/> -->
                    </div>
                    <div class="col-12 col-sm-6 d-flex flex-column  position-sm-relative bottom-0 mb-sm-0 contentbg">
                        <div class="flex-row d-flex justify-content-between title-div px-3">
                            <div class=" d-flex m-2">
                                <span class="bigtext">现场与网投同步开拍</span>
                            </div>
                            <div class=" d-flex m-2">
                                <span class="kf text-white px-2" type="button" @click="toChat(chaturl)">客服咨询</span>
                            </div>
                        </div>
                        <div class="flex-row d-flex justify-content-between flex-grow-1 align-items-center px-3">
                            <div class=" d-flex mt-2 ms-1">
                                <img style="max-width:4.6rem" src="@/assets/images/conlogo.png"/>
                            </div>
                            <div class="d-flex flex-column m-2 text-white text-start flex-grow-1 align-items-center">
                                <div class="">
                                    <span>龙源国际</span>
                                    <span class="gw p-1 px-3 ms-3">官网</span>
                                </div>
                                <div>
                                    <i class="bi bi-star-fill star"></i>
                                    <i class="bi bi-star-fill star"></i>
                                    <i class="bi bi-star-fill star"></i>
                                    <i class="bi bi-star-fill star"></i>
                                    <i class="bi bi-star-fill star"></i>
                                    <span class="small">9999+好评</span>
                                </div>
                                <div>
                                    <span class="small">万人在线  4.2亿用户选择</span>
                                </div>
                            </div>
                            <div class=" d-flex mt-2">
                                <img style="max-width:4.6rem" src="@/assets/images/vip.png"/>
                            </div>
                        </div>
                        <div class="flex-row d-flex justify-content-center px-3 mb-4 mb-sm-3">
                            <div class=" d-flex m-2 me-5">
                                <img style="max-width:8.8rem" @click="goLink(register)" src="@/assets/images/zc.png"/>
                            </div>
                            <div class=" d-flex m-2">
                                <img style="max-width:8.8rem" @click="goLink(login)" src="@/assets/images/dl.png"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        
        <!-- 底部导航栏 -->
        <div class="flex-row d-flex justify-content-center bg-black text-white order-last text-nowrap small pt-2 px-3 index-1">
            <div class="d-flex m-2" @click="toPage('videos')" role="button">
                <img style="max-width:4.8rem" src="@/assets/images/conlogo.png"/>
            </div>
            <div class="d-flex flex-column justify-content-center col text-start m-2" role="button">
                <div class="">
                    <span>下载手机APP应用</span>
                </div>
                <div>
                    <span class="footyy">乾坤未定  你我皆黑马</span>
                </div>
                <div>
                    <i class="bi bi-star-fill star"></i>
                    <i class="bi bi-star-fill star"></i>
                    <i class="bi bi-star-fill star"></i>
                    <i class="bi bi-star-fill star"></i>
                    <i class="bi bi-star-fill star"></i>
                    <span>9999+好评</span>
                </div>
                
            </div>
            <div class="d-flex flex-column justify-content-around align-items-end col text-end m-2" role="button">
                <img style="max-width:5.8rem" @click="goLink(iosApp)" src="@/assets/images/ios.png"/>
                <img style="max-width:5.8rem" @click="goLink(androidApp)" src="@/assets/images/android.png"/>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return {
             chaturl:config.promotionList.chaturl,
             register:config.promotionList.register,
             login:config.promotionList.login,
             iosApp:config.promotionList.iosApp,
             androidApp:config.promotionList.androidApp,
        }
    },
    methods:{
        /**
         * 跳转到客服链接
         */
        toChat(url){
            window.open(url,'_blank');
        },
        goLink(url){
            window.location.href=url;
        }
    }
}
</script>