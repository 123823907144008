<style scoped>
	ul{
		padding-left: 0;
		margin-bottom: 0;
	}
	a{
		text-decoration: none;
	}
	.hero-btn>a:hover{
        animation: hero-btn 2s ease-in infinite forwards;
    }
    @keyframes hero-btn {
        from{
            box-shadow: 0 0 0px yellow;
        }
        30%,100%{
            box-shadow: 0 0 10rem transparent;
        }
    }
	.owl-carousel.owl-hidden{
		opacity:1;
	}
	.qr-img{
		padding: 0 60px;
	}
	.header-area .logo img{
		width: 60%;
	}
</style>
<template>
	<!-- back to top start -->
    <!-- <div class="progress-wrap">
        <svg class="progress-circle svg-content" width="100%" height="100%" viewbox="-1 -1 102 102">
           <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98"></path>
        </svg>
     </div> -->
	 <div class="wrapper">
		<!-- header area -->
		<header id="sticky-header">
			<div class="header-area">
				<div class="container">
					<div class="row al-center">
						<div class="col-lg-2">
							<div class="logo">
								<a href=""><img src="images/logo.png" alt="messing"></a>
							</div>
						</div>
						<div class="col-lg-10">
							<div class="main-manu">
								<ul>
									<li>
										<a @click="goAnchor('#trending-slot')" href="javascript:;">
											<img src="picture/popular.svg" alt="messing">热门精选
										</a>
									</li>
									<li><a @click="goAnchor('#game-casino')" href="javascript:;">全部游戏</a></li>
									<li><a @click="goAnchor('#card')" href="javascript:;">APP下载</a></li>
									<li><a @click="goAnchor('#contact')" href="javascript:;">联系我们</a></li>
								</ul>
							</div>
							<!-- Only Show On Mobile Devices -->
							<div class="mobila-manu-area">
								<div class="main-menu d-lg-none">
									<nav id="mobile-menu">
										<ul>
											<li class="active"><a href="">热门精选</a></li>
											<li><a href="#">全部游戏</a></li>
											<li><a href="#">APP下载</a></li>
											<li><a href="#">联系</a></li>
										</ul>
									</nav>
								</div>
								<div class="mobile-menu"></div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</header>
		
		<!-- hero area -->
		<div class="hero-area">
			<div class="container">
				<div class="row">
					<div class="col-lg-12">
						<div class="hero-all-content">
						<div class="hero-content">
							<div class="double-content">
								<h2 class="up">{{title1}}</h2>
								<h2 class="down">{{title1}}</h2>
							</div>
							<p v-html="titleWords1"></p>
							<div class="hero-btn">
								<a  @click="goAnchor('#card')" href="javascript:;">开启畅玩之旅<i class="bi bi-arrow-right"></i></a>
							</div>
						</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- trading slot -->
		<div id="trending-slot" class="trading-area">
			<div class="container">
				<div class="trading-tittle">
					<h2>热门精选</h2>
				</div>
				<div class="owl-carousel trending-slider">
					<div class="single-trending-item">
						<div class="trending-left">
							<img src="picture/trending1.jpg" alt="messing">
						</div>
						<div class="trending-right">
							<h2>真人轮盘</h2>
							<p>Roulette</p>
						</div>
					</div>
					<div class="single-trending-item">
						<div class="trending-left">
							<img src="picture/bac.jpg" alt="messing">
						</div>
						<div class="trending-right">
							<h2>真人百家</h2>
							<p>Baccarat</p>
						</div>
					</div>
					<div class="single-trending-item">
						<div class="trending-left">
							<img src="picture/bull.jpg" alt="messing">
						</div>
						<div class="trending-right">
							<h2>真人牛牛</h2>
							<p>Classic Bull</p>
						</div>
					</div>
					<div class="single-trending-item">
						<div class="trending-left">
							<img src="picture/dezhou.jpg" alt="messing">
						</div>
						<div class="trending-right">
							<h2>真人德州</h2>
							<p>Texas Poker</p>
						</div>
					</div>
					<div class="single-trending-item">
						<div class="trending-left">
							<img src="picture/lucky.jpg" alt="messing">
						</div>
						<div class="trending-right">
							<h2>老虎机</h2>
							<p>Lucky slot machine</p>
						</div>
					</div>
					<div class="single-trending-item">
						<div class="trending-left">
							<img src="picture/touzi.jpg" alt="messing">
						</div>
						<div class="trending-right">
							<h2>真人骰子</h2>
							<p>Dice</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<!-- gaming area -->
	<div id="game-casino" class="gaming-area">
		<div class="container">
			<div class="all-collection-manu mb-4">
				<ul class="filter-project">
					<li class="active" data-filter="*">
						<img src="picture/all-slots.svg" alt="messing">&nbsp;
						<span>全部</span>  
					</li>
					<li data-filter=".black">
						<img src="picture/black-jack.svg" alt="messing">&nbsp;
						<span>真人游艺</span>
					</li>
					<li data-filter=".casino">
						<img src="picture/casino.svg" alt="messing">&nbsp;
						<span>电子竞技</span>
					</li>
					
				</ul>
			</div>
			<div class="all-collection-item">
				<div class="row project-list">
					<div class="col-lg-3 col-md-4 black pro">
						<div class="single-gaming-item">
							<div class="gaming-img overflow-hidden">
								<img src="picture/bac.jpg" alt="messing">
							</div>
							<div class="gaming-hover">
								<a class="demo">真人百家</a>
							</div>
						</div>
					</div>
					<div class="col-lg-3 col-md-4 casino">
						<div class="single-gaming-item">
							<div class="gaming-img overflow-hidden">
								<img src="picture/lucky.jpg" alt="messing">
							</div>
							<div class="gaming-hover">
								<a class="demo">老虎机</a>
							</div>
						</div>
					</div>
					<div class="col-lg-3 col-md-4 black">
						<div class="single-gaming-item">
							<div class="gaming-img overflow-hidden">
								<img src="picture/trending1.jpg" alt="messing">
							</div>
							<div class="gaming-hover">
								<a class="demo">真人轮盘</a>
							</div>
						</div>
					</div>
					<div class="col-lg-3 col-md-4 casino">
						<div class="single-gaming-item">
							<div class="gaming-img overflow-hidden">
								<img src="picture/dezhou.jpg" alt="messing">
							</div>
							<div class="gaming-hover">
								<a class="demo">电子德州</a>
							</div>
						</div>
					</div>
					<div class="col-lg-3 col-md-4 black">
						<div class="single-gaming-item">
							<div class="gaming-img overflow-hidden">
								<img src="picture/touzi.jpg" alt="messing">
							</div>
							<div class="gaming-hover">
								<a class="demo">真人骰子</a>
							</div>
						</div>
					</div>
					<div class="col-lg-3 col-md-4 black">
						<div class="single-gaming-item">
							<div class="gaming-img overflow-hidden">
								<img src="picture/bull.jpg" alt="messing">
							</div>
							<div class="gaming-hover">
								<a class="demo">真人牛牛</a>
							</div>
						</div>
					</div>
					<div class="col-lg-3 col-md-4 black">
						<div class="single-gaming-item">
							<div class="gaming-img overflow-hidden">
								<img src="picture/3gong.jpg" alt="messing">
							</div>
							<div class="gaming-hover">
								<a class="demo">真人三公</a>
							</div>
						</div>
					</div>
					<div class="col-lg-3 col-md-4 black">
						<div class="single-gaming-item">
							<div class="gaming-img overflow-hidden">
								<img src="picture/coin.jpg" alt="messing">
							</div>
							<div class="gaming-hover">
								<a class="demo">真人钢镚</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
 <!-- card area -->
 <div class="card-area">
    <div class="container">
        <div class="row card-item">
            <div class="col-lg-4 col-md-6 ps-lg-4 pe-lg-4">
                <div class="single-card-item">
                    <div class="cart-img h-100 overflow-hidden">
                        <img class="h-100" src="picture/card1.png" alt="messing">
                    </div>
                    <div class="card-content">
						<h2>移动APP</h2>
                        <p>不想每次都输入网址？我们提供专业的手机APP下载，让您更加快速打开游戏，为您提供沉浸式体验，一次下载，次次方便！</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 ps-lg-4 pe-lg-4">
                <div class="single-card-item larze">
                    <div class="cart-img h-100 overflow-hidden">
                        <img class="h-100" src="picture/card2.png" alt="messing">
                    </div>
                    <div class="card-content">
                        <h2>H5游戏</h2>
                        <p>网页版游戏，可适应电脑、手机、平板等不同设备屏幕大小，支持横竖屏多种方式随时旋转变换，无需下载与安装，便捷式游戏。</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 ps-lg-4 pe-lg-4">
                <div class="single-card-item">
                    <div class="cart-img h-100 overflow-hidden">
                        <img class="h-100" src="picture/card3.png" alt="messing">
                    </div>
                    <div class="card-content">
                        <h2>实时服务</h2>
                        <p>如果您在游戏中有任何问题或者有给予我们的宝贵建议，我们有专业的客服与您联系并为您提供服务，实时为您在线解答疑惑。</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 ps-lg-4 pe-lg-4">
                <div class="single-card-item small">
                    <div class="cart-img h-100 overflow-hidden">
                        <img class="h-100" src="picture/card4.png" alt="messing">
                    </div>
                    <div class="card-content">
                        <h2>VIP Service </h2>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 ps-lg-4 pe-lg-4">
                <div class="single-card-item small">
                    <div class="cart-img h-100 overflow-hidden">
                        <img class="h-100" src="picture/card5.png" alt="messing">
                    </div>
                    <div class="card-content">
                        <h2>24/7 Support </h2>
                    </div>
                </div>
            </div>
        </div>
        <div id="card" class="cta-ara">
            <h2>Are you ready to play?</h2>
            <div class="cta-btn">
                <a v-for="(item,i) in cardList.slice(0,3)" :key="item" @click="goLink(item.url[0])" href="javascript:;" class="ms-2 me-2">
					<i :class="{'bi-browser-chrome':i==0,'bi-apple':i==1,'bi-android2':i==2}" class="bi me-1"></i>{{item.title}}
				</a>
            </div>
        </div>
		<div class="deposite-area">
			<div class="deposit-qr">
				<div class="qr-bar">
					<p>您也可以选择扫码下载</p>
				</div>
				<div class="qr-tittle">
					<p>温馨提示： <span>{{appTip}}</span></p>
				</div>
				<div class="d-flex justify-content-evenly mb-3">
					<div v-for="item in urlList" :key="item" class="qr-img" @click="goLink(item.url)" role="button">
						<vue-qr class="img-thumbnail rounded" :margin="10" :text="item.url"></vue-qr>
						<span class="small" style="display: inherit;">{{item.text}}</span>
					</div>
				</div>
			</div>
		</div>
		
    </div>
 </div>
 <!-- footer area start -->
  <footer class="footer-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-5 col-md-4">
                <div class="logo-part">
                     <div class="logo">
                        <a href="javascript:;"> <img src="images/logo.png" alt="messing"></a>
                     </div>
                     <p>更多精彩游戏，尽在龙源国际！ <br> More exciting games, all at Longyuan International ! <br> 欢迎加入！</p>
                </div>
            </div>
            <div class="col-lg-2 col-md-2">
                <div class="information-part">
                    <h4>联系方式</h4>
                    <ul>
                        <li v-for="item in telList" :key="item" >
							{{item.text}}：
							<a @click="goLink(item.url)" href="javascript:;">{{item.telNumberN}}</a>
						</li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-5 col-md-5">
                <div id="contact" class="contact-part">
                    <h4>{{problemTitle}}</h4>
                    <ul>
                        <li>{{problemCon1}}</li>
                        <li>{{problemCon2}}</li>
                    </ul>
                </div>

                <div class="contact-part">
                    <h4>在线客服</h4>
                    <ul>
                        <li>客服24小时在线，如有需要，请随时联系我们！</li>
                        <li><a @click="goLink(chatList[0].url)" href="javascript:;" class="join">
							<i class="bi bi-chat-dots-fill me-1"></i>{{chatList[0].text}}</a>
						</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
  </footer>
  <!-- copyright -->
<div class="copyright-area">
    <div class="container">
        <div class="row">
            <div class="copyright-wrap">
                <div class="left-copyright">
                    <p>{{footCopyRight}}</p>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
// @ is an alias to /src
import vueQr from 'vue-qr/src/packages/vue-qr.vue'

export default {
	name: 'HomeView',
	components: {
		vueQr
	},
	data(){
		return {
			isShowModal:true,
			title1:config.title1,
          	titleWords1:config.titleWords1,
			notice:config.notice,
			cardList:config.cardList,
			urlList:config.urlList,
			appTip:config.appTip,
			chatList:config.chatList,
			telList:config.telList,
			problemTitle:config.problemTitle,
			problemCon1:config.problemCon1,
			problemCon2:config.problemCon2,
			footCopyRight:config.footCopyRight
		}
	},
	mounted(){
		setTimeout(()=>{

			//  trendig slot area
			$('.trending-slider').owlCarousel({
				loop: true,
				autoplay: true,
				margin:20,
				smartSpeed: 1500,
				dots: false,
				nav: false,
				navText: ["<i class='bi bi-arrow-left''></i>", "<i class='bi bi-arrow-right''></i>"],
				responsive: {
					0: {
						items:1,
						nav: false,
					},
					600: {
						items: 1,
						nav: false,
					},
					768: {
						items: 3
					},
					992: {
						items: 3
					},
					1000: {
						items: 4
					},
					1920: {
						items: 4
					}
				}
			})
			// game isotope(home page)
			$(".project-list").isotope();
		
			$(".filter-project li").on('click', function(){
				$(".filter-project li").removeClass("active");
				$(this).addClass("active");
				var selector = $(this).attr("data-filter");
				$(".project-list").isotope({
					filter: selector,
					
				});
			});
			// card isotope(home page)
			$(".card-item").isotope();
	
			 // Meanmenu 
			$('#mobile-menu').meanmenu({
				meanMenuContainer: '.mobile-menu',
				meanScreenWidth: "991",
				meanMenuOpen: "<span></span> <span></span> <span></span>",
				onePage: false,
			});

			// sticky Header
			var wind = $(window);
			wind.on('scroll', function () {
				var sticky = $('#sticky-header');
				var scroll = wind.scrollTop();
				if (scroll < 100) {
					sticky.removeClass('sticky');
				} else {
					sticky.addClass('sticky');
				}
			});
		},400)
	},
	methods:{
		goLink(url){
			window.location.href=url;
		},
		goAnchor (selector) {
			document.querySelector(selector).scrollIntoView({
				behavior:"smooth"
			})
		}
    }
};
</script>
