<style>
    .videos-view{width: 100%; background-color:white;display: flex;}
    .btn-item{
        background: linear-gradient(0deg,#f3ac45 0%, #f35a42 50%, #f04f61 100%);
        color: #fff1a5 !important;
        border-color: #fbd080;  
    }
    .btn-downitem{
        background: linear-gradient(0deg,#51405d 0%, #a87ac9 50%, #51405d 100%);
        color: white !important;
        border-color: #64a7c4;
    }
    h3{color: #6dafc4;text-align: left; font-weight: bold;}
    p{text-align: left;}
</style>
<template>
    <div class="d-flex flex-grow-1 justify-content-start justify-content-sm-center videos-view flex-column position-relative">
        <div class="d-flex flex-column flex-sm-row">
            <div class="col-12 col-sm-5">
                <img class="mx-auto d-block img-fluid" src="@/assets/images/videosbg.png"/>
            </div>
            <div class="col-12 col-sm-7 position-xs-absolute position-sm-relative bottom-0 mb-3 mb-sm-0 px-3 pt-2">
                <h3 class="fw-bold fs-2" style="color:#b81715;">视讯真人</h3>
                <p style="color:#8b4b13;">{{videosCon}}</p>
                <div class="d-flex pb-3">
                    <div class="col-4 col-xl-2 pe-2">
                        <img class="mx-auto d-block" width="100%" src="@/assets/images/baijia.png"/>
                    </div>
                    <div class="col-4 col-xl-2 pe-2">
                        <img class="mx-auto d-block" width="100%" src="@/assets/images/longhu.png"/>
                    </div>
                    <div class="col-4 col-xl-2 pe-2">
                        <img class="mx-auto d-block" width="100%" src="@/assets/images/cow.png"/>
                    </div>
                    <div class="col-4 col-xl-2 pe-2">
                        <img class="mx-auto d-block" width="100%" src="@/assets/images/dezhou.png"/>
                    </div>
                    <div class="col-4 col-xl-2 pe-2">
                        <img class="mx-auto d-block" width="100%" src="@/assets/images/majiang.png"/>
                    </div>
                    <div class="col-4 col-xl-2 pe-2">
                        <img class="mx-auto d-block" width="100%" src="@/assets/images/lucktiger.png"/>
                    </div>
                </div>
                
                <div class="d-flex justify-content-center rounded py-2 px-3 p-sm-4" >
                    <div class="d-flex flex-column justify-content-between text-nowrap align-items-center">
                        <div class="fw-bold fs-6" style="color:#8b4b13;">更多精彩游戏，尽在龙源国际</div>
                        <div class="fw-bold small" style="color:#8b4b13;">More exciting games, all at Longyuan International</div>
                        
                        <div class="d-flex flex-wrap pt-2">
                            <button v-for="item in newsList" :key="item" type="button" :class="{'d-none':item.isMobileShow==false}" class="btn btn-item me-sm-2 mb-2 d-lg-block" @click="goLink(item.url)">{{item.text}}</button>
                        </div>
                    </div>
                   
                </div>

                <!-- <div class="d-flex flex-wrap justify-content-between justify-content-sm-start text-nowrap">
                    <button v-for="item in newsList" :key="item" type="button" :class="{'d-none':item.isMobileShow==false}" class="btn btn-item me-sm-2 mb-2 d-lg-block" @click="goLink(item.url)">{{item.text}}</button>
                </div> -->
            </div>
        </div>
    </div>
</template>
<script>

export default {
    data(){
        return {
          videosCon:config.videosCon,
          newsList:config.newsList
        }
    },
    methods:{
      goLink(url){
        window.location.href=url;
      }
    }
}
</script>
