<style scoped>
    .bgcolor{
        background-color: #b32c04;
    }
    .navbar{background-color: #371b43}
    .active-navli{
        background: linear-gradient(0deg,#f3ac45 0%,#f35a42 50%, #f04f61 100%);
        border-top-left-radius: 0.5em;
        border-top-right-radius: 0.5em;
    }
</style>
<template>
    <!-- <nav v-if="$route.name!='login'" class="navbar navbar-expand-sm navbar-dark p-0 mb-0" style="align-items: flex-end;">
        <a class="navbar-brand ps-5" href="#"><img src="@/assets/images/logo.png"/></a>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#collapsibleNavbar">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse justify-content-center" id="collapsibleNavbar">
            <ul class="navbar-nav">
                <li class="nav-item ps-3 pe-3" :class="{'active-navli':currPage=='home'}" @click="toPage('home')">
                    <span class="nav-link text-white text-center" role="button">首页</span>
                </li>
                <li class="nav-item ps-3 pe-3" :class="{'active-navli':currPage=='videos'}" @click="toPage('videos')">
                    <span class="nav-link text-white text-center" role="button">视讯</span>
                </li>
                <li class="nav-item ps-3 pe-3" :class="{'active-navli':currPage=='phone'}" @click="toPage('phone')">
                    <span class="nav-link text-white text-center" role="button">电投</span>
                </li>
                <li class="nav-item ps-3 pe-3" :class="{'active-navli':currPage=='contact'}" @click="toPage('contact')">
                    <span class="nav-link text-white text-center" role="button">联系</span>
                </li>
                <li class="nav-item ps-3 pe-3" :class="{'active-navli':currPage=='appdown'}" @click="toPage('appdown')">
                    <span class="nav-link text-white text-center" role="button">APP下载</span>
                </li>
            </ul>
        </div>
    </nav> -->
    <!-- PC端导航栏 -->
    <div class="bgcolor" >
        <div class="container">
            <div class="d-flex justify-content-between">
                <div class="py-2">
                    <img style="max-width:5.8rem" src="images/logo.png"/>
                </div>
                <div class="d-none d-sm-flex text-white mt-2 text-nowrap">
                    <div class="d-flex flex-column justify-content-center px-sm-3 px-md-4" :class="{'active-navli':currPage=='home'}" @click="toPage('home')" role="button">
                        <span>首页</span>
                    </div>
                    <div class="d-flex flex-column justify-content-center px-sm-3 px-md-4" :class="{'active-navli':currPage=='videos'}" @click="toPage('videos')" role="button">
                        <span>视讯</span>
                    </div>
                    <div class="d-flex flex-column justify-content-center px-sm-3 px-md-4" :class="{'active-navli':currPage=='phone'}" @click="toPage('phone')" role="button">
                        <span>电投</span>
                    </div>
                    <div class="d-flex flex-column justify-content-center px-sm-3 px-md-4" :class="{'active-navli':currPage=='contact'}" @click="toPage('contact')" role="button">
                        <span>联系</span>
                    </div>
                    <div class="d-flex flex-column justify-content-center px-sm-3 px-md-4" :class="{'active-navli':currPage=='appdown'}" @click="toPage('appdown')" role="button">
                        <span>APP下载</span>
                    </div>
                </div>
                <div class="d-flex flex-column d-sm-none fs-3 text-white justify-content-center">
                    <i class="bi bi-house-fill" @click="toPage('home')" role="button"></i>
                </div>
            </div>
        </div>
    </div>
    <!-- 移动端底部导航栏 -->
    <div class="d-flex d-sm-none bgcolor text-white order-last text-nowrap small pt-2 index-1">
        <div class="d-flex flex-column justify-content-center col" :class="{'text-info':currPage=='videos'}" @click="toPage('videos')" role="button">
            <i class="bi bi-globe fs-5 lh-1"></i>
            <span>视讯</span>
        </div>
        <div class="d-flex flex-column justify-content-center col" :class="{'text-info':currPage=='phone'}" @click="toPage('phone')" role="button">
            <i class="bi bi-dice-6-fill fs-5 lh-1"></i>
            <span>电投</span>
        </div>
        <div class="d-flex flex-column justify-content-center col" :class="{'text-info':currPage=='contact'}" @click="toPage('contact')" role="button">
            <i class="bi bi-people-fill fs-5 lh-1"></i>
            <span>联系</span>
        </div>
        <div class="d-flex flex-column justify-content-center col" :class="{'text-info':currPage=='appdown'}" @click="toPage('appdown')" role="button">
            <i class="bi bi-cloud-arrow-down-fill fs-5 lh-1"></i>
            <span>APP下载</span>
        </div>
    </div>
</template>
<script>
    export default{
        name: 'navbar',
        props: {
            msg: String,
        },
        data(){
            return {
                currPage:"home",//当前选中页
            }
        },
        methods:{
            toPage(nameurl){
                this.currPage=nameurl;
                this.$router.push({
                    name:nameurl,
                    params:{

                    }
                })

            }
        }
    };
</script>