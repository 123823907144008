import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import VideosView from '../views/VideosView.vue'
import PhoneView from '../views/PhoneView.vue'
import ContactView from '../views/ContactView.vue'
import AppDownView from '../views/AppDownView.vue'
import LoginView from '../views/LoginView.vue'
import PromotionView from '../views/PromotionView.vue'

const routes = [
  {
    path: '/',
    name: 'login',
    component: LoginView
  },
  {
    path: '/home',
    name: 'home',
    component: HomeView
  },
  {
    path: '/videos',
    name: 'videos',
    component: VideosView
  },
  {
    path: '/phone',
    name: 'phone',
    component: PhoneView
  },
  {
    path: '/contact',
    name: 'contact',
    component: ContactView
  },
  {
    path: '/appdown',
    name: 'appdown',
    component: AppDownView
  },
  {
    path: '/promotion',
    name: 'promotion',
    component: PromotionView
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
