<template>
	<div class="">
		<!-- <navbar v-if="$route.name!='login' && $route.name!='promotion'"/> -->
		<!-- <div class="flex-grow-1 d-flex flex-column flex-sm-row justify-content-start justify-content-sm-center h-0 bg-purple-50">
			
		</div> -->
		<router-view/>
		<!-- <foot v-if="$route.name!='login' && $route.name!='promotion'" class="d-none d-sm-flex"></foot> -->
	</div>
</template>
<script>
    import navbar from '@/components/navbar.vue'; 
    import foot from '@/components/footer.vue'; 
    
    export default{
      name: 'App',
      components: {
        navbar,
        foot
      },
    };
</script>
<style>
	.overflow-y-scroll{
		overflow-y: scroll;
	}
	.overflow-x-hidden{
		overflow-x: hidden;
	}
	.scroll-none::-webkit-scrollbar{
		display: none;
	}
	html,body{
		height: 100%;
	}
	.index-1{
		z-index: 1;
	}
	.h-0{
		height: 0;
	}
	.h-100{
		height:100%;
	}
	@media (max-width:575px) {
		.position-xs-absolute{
			position: absolute;
		}
	}
	@media (min-width:576px) and (max-width:767px) {
		.position-sm-relative{
			position:relative;
		}
	}
	@media (min-width:576px) {
		.h-sm-auto{
		height: auto;
		}
	}
</style>
