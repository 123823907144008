// 底部栏
<style scoped>
footer{background-color: #b32c04}
</style>
<template>
    <footer v-if="$route.name!='login'" class=" text-white text-center pt-2 pb-2">
        <div v-html="footCopyRight" class="container">
        </div>
    </footer>
</template>
<script>
export default {
    data(){
        return {
            footCopyRight:config.footCopyRight
        }
    }
}
</script>