<style>
    .appdown-view{width: 100%; background-color:white;}
</style>
<template>
    <div class="appdown-view p-3 h-100 overflow-scroll">
        <div class="row">
            <div class="alert alert-info alert-dismissible">
                <button type="button" class="btn-close" data-bs-dismiss="alert"></button>
                <strong>温馨提示：</strong>{{appTip}}
            </div>
            <div v-for="item in urlList" :key="item" class="col-4 col-sm-3 col-md-2 mb-3" @click="goApp(item.url)" role="button">
                <vue-qr class="img-thumbnail bg-transparent rounded" :margin="10" colorDark="#0dcaf0" :text="item.url"></vue-qr>
                <span class="small" style="display: inherit;">{{item.text}}</span>
            </div>
        </div>
    </div>
</template>
<script>
    import vueQr from 'vue-qr/src/packages/vue-qr.vue'
    export default {
        components: { vueQr },
        data(){
            return {
                urlList:config.urlList,
                appTip:config.appTip,
            }
        },
        methods:{
            /**
             * 跳转到下载链接
             */
            goApp(url){
                window.open(url,'_blank');
            }
        }
    }
</script>